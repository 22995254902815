import React from 'react';
import {DataFrame, Field, PanelProps} from '@grafana/data';
import { SimpleOptions } from 'types';
import { css, cx } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';
//let sampledata = require("./data").sampledata;


interface Props extends PanelProps<SimpleOptions> {}

const MIN_ERROR_INTERVAL_MS = 5000;

const getStyles = () => {
  return {
    wrapper: css`
      position: relative;
    `,
    svg: css`
      position: absolute;
      top: 0;
      left: 0;
    `,
    textBox: css`
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px;
    `,
	img: css`
	  width: 2em;
	  background: #0A0A0A;
	  border-radius: 25px;
	  margin-right: 0.5em;
	`,
    table: css`
      border-spacing: 20px 0;
      border-collapse: separate;
    `, tableholder: css`
        height: 100%;
      overflow-y: scroll;
    `

  };
};

function findColumn(fields: Field[], name: string): number{
  for (let i = 0; i < fields.length; i++) {
    if(fields[i].name === name){
      return i;
    }
  }
  return -1;
}

class Event{
  value: string;
  name: string;
  time: number;
  constructor(value: string,time: number) {
    let underscore_idx = value.lastIndexOf("_");
    this.name = value.substring(0, underscore_idx);
    this.value = value.substring(underscore_idx+1);
    this.time = time;
  }
  public toString(): string{
    return this.time+" " +this.name+" "+this.value;
  }
}

function santizeName(name: string): string{
  if(name.startsWith("TT")){
    name = name.substring(2);
  }
  name = name.replaceAll("_"," ");

  return name.trim();
}

function findEvents(values: any, times: any): Event[] {
  let events: Event[] = []
  for(let i = 0; i < values.length; i++){
    let value = values[i];
    if(value.includes("WARNING") || value.includes("ERROR") || value.includes("FAILURE")){
      events.push(new Event(value,times[i]))
    }
  }
  return events;
}

function filterEvents(events: Event[]) {
  // remove elements if there is another one with the same name and "OFF" within the timeframe
  for(let i = 0; i < events.length; i++) {
    let found_dup = false;
    for(let dupi=i+1; dupi<events.length; dupi++){
      if(events[dupi].time > events[i].time+MIN_ERROR_INTERVAL_MS){
        break;
      }else if(
          events[dupi].name === events[i].name && events[dupi].value === "OFF" ){
        events.splice(dupi,1);
        dupi--;
        found_dup = true;
      }

    }

    if(found_dup){
      events.splice(i,1);
      i--;
    }
  }
}

function parseSeries(fields: Field[]): Event[]{
  // find columns
  const valColumn = findColumn(fields, 'value');
  const timeColumn = findColumn(fields, 'time');


  let events = findEvents(fields[valColumn].values, fields[timeColumn].values);

  filterEvents(events)


  return events;

}

export const SimplePanel: React.FC<Props> = ({ options, data, width, height }) => {
  //const theme = useTheme2();
  const styles = useStyles2(getStyles);
    let events = parseSeries(data.series[0].fields);

  function findImageforvalue(value: string) {
    let image = null;
    if(value === "RED"){
      image = "error";
    }else if(value === "YELLOW"){
      image = "yellow";
    }else if(value === "OFF"){
      image = "off";
    }else if(value === "INFO"){
      image = "info";
    }
    if(image != null) {
      let path = "/public/plugins/pilabs-dashboardicons-panel/img/"+image+".svg";
      return <img className={styles.img} src={path} />
    } else {
      return value;
    }
  }

  return (

      <div
          className={cx(
              styles.wrapper,
        css`
          width: ${width}px;
          height: ${height}px;
        `
      )}
    >
        <div className={styles.tableholder}>


      <table className={styles.table}>
        {events.reverse().map(value => {
          return <tr key={value.time}>
            <td>{new Date(value.time).toLocaleString()}</td>
            <td>{santizeName(value.name)}</td>
            <td>{
              findImageforvalue(value.value)
              }
              </td>
          </tr>;
        })}

      </table>
        </div>


    </div>
  );
};
